<template>
  <div>
    <div class="container-fluid log-in-bg">
      <div class="container center-div">
        <div class="container logo">
          <div class="row justify-content-center">
            <div class="col-12">
              <img src="../assets/icons/logo-coopharma.png" class="logo" />
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-6 col-md-7 col-sm-12 col-xs-12">
            <div class="card">
              <div class="card-body">
                <form>
                  <div class="row justify-content-center">
                    <h1 class="login-title">
                      "¿Olvidaste Contraseña?"
                      <!-- {{
                        languaje == "es"
                          ? "¿Olvidaste Contraseña?"
                          : "Did you forget your password?"
                      }} -->
                    </h1>
                    <div class="col-12 inputContainer">
                      <div class="row justify-contain-center">
                        <div class="col-1">
                          <i class="icon fas fa-user"></i>
                        </div>
                        <div class="col-11">
                          <!--  v-if="languaje == 'es'"  -->
                          <input
                            class="email"
                            type="email"
                            placeholder="Correo Electrónico"
                            v-model="email"
                          />
                          <!-- <input
                            v-else
                            class="email"
                            type="email"
                            placeholder="Email"
                            v-model="email"
                          /> -->
                          <p class="required" v-if="esCorreo">
                            "Debe suministrar un correo valido"
                            <!-- {{
                              languaje == "es"
                                ? "Debe suministrar un correo valido"
                                : "You have to supply a valid email"
                            }} -->
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <router-link class="forgot-btn" to="/">
                    <h5
                      style="font-size: 20px; font-weight: 300; text-align: center; margin-top: 20px"
                    >
                      "¿Tienes cuenta?"
                      <!-- {{
                        languaje == "es"
                          ? "¿Tienes cuenta?"
                          : "Do you have an account?"
                      }} -->
                    </h5>
                  </router-link>
                  <div class="button-position">
                    <button
                      @click="sendEmailRestoreUser"
                      class="btn submit-btn"
                    >
                      <!-- {{ languaje == "es" ? "Enviar" : "Send" }} -->
                      Enviar
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import Parse from "parse";
export default {
  name: "ForgotPassword",
  data() {
    return {
        email: '',
        password: null,
        admin: null,
        superAdmin: null,
        esCorreo: false,
    };
  },
  watch: {
    email() {
      if(this.email === "") {
        this.esCorreo = true;
      } else {
        this.esCorreo = false;
      }
    }
  },
  mounted() {
    this.languaje = 'es';
    // navigator.language || navigator.userLanguage;
    // this.languaje = this.languaje.substr(0,2);
    // this.superAdmin = Parse.User.current().get("superAdmin");
    // this.admin = Parse.User.current().get("isAdmin");
    // if (this.admin && !this.superAdmin) {
    //   this.$router.push("/userTabs");
    // }
    // if (!this.admin && !this.superAdmin) {
    //   this.$router.push("/");
    // }
    // this.$swal({
    //   title: "Error",
    //   text: "Algo ha ocurrido durante el proceso, Verifique",
    //   type: "error",
    // });
  },
  methods: {
    // proccessForgotPassword() {
    //   let userID = "";
    //   let storeID = "";
    //   let correo = "";
    //   let nombre = "";
    //   const query = new Parse.Query("_User");
    //   const query2 = new Parse.Query("Store");
    //   query.equalTo("email", this.email);
    //   query.find().then((usuario) => {
    //     this.esCorreo = false;
    //     userID = usuario[0].id;
    //     correo = usuario[0].attributes.username;
    //     nombre = usuario[0].attributes.fullName;
    //     query2.count().then((storeCount) => {
    //       query2.limit(storeCount);
    //       query2.find().then((stores) => {
    //         for(let store of stores) {
    //           if(store.attributes.employee.id === userID){
    //             storeID = store.id;
    //             // this.proccessEmailRestoring({
    //             //   Nombre: nombre,
    //             //   FarmaciaID: storeID,
    //             //   Correo: correo,
    //             // });
    //           }
    //         }
    //       })
    //     });
    //   }).catch(() => {
    //     this.esCorreo = true;
    //   });
    // },
    sendEmailRestoreUser() {
        // cuerpoCorreo
        Parse.User.requestPasswordReset(this.email)
        // Email.send({
        //   Host : "smtp.gmail.com",
        //   Username : "myshop@coopharma.coop",
        //   Password : "Mysh0p2020",
        //   To : cuerpoCorreo.Correo,
        //   From : "myshop@coopharma.coop",
        //   Subject : "Restablecer Password",
        //   Body : "<div style='background-color:#E0E0E0; padding: 20px; width: 80%;'>"+
        //           "<a href='www.coopharma.coop'><img src='https://coopharma.app/img/logo-coopharma.e5f23e05.png'></a>"+
        //           "</div>"+
        //           "<div style='margin:40px; width:70%; text-align: justify;'>"+
        //           "<p style = 'font-size: 20px;'>Hola, "+cuerpoCorreo.Nombre+"</p>"+
        //           "<p style = 'font-size: 20px;'>Este es un correo de reestablecimiento de usuario en la plataforma de "+
        //           "<a href='www.coopharma.app' style='color:#0E9390; font-weight:bold;'>www.coopharma.app</a>"+
        //           ", Para poder proceder acceda via el boton a continuacion en este correo:</p>"+
        //           "<div><a href='www.coopharma.app/?#/passwordRestore/"+cuerpoCorreo.FarmaciaID+"'><button style='width:100%;'><p>"+
        //           "<img src='https://coopharma.app/img/logo-coopharma.e5f23e05.png' style='width:150px;'>"+
        //           "<p style='font-size: 20px; font-weight:bold; color: #ED7422;'>Cambiar Password de Plataforma</p></button></a></div></div>"+
        //           "<br><br><p>Para cualquier duda o inconveniente puede llamar a (787) 520-6018</p>",
        // });
    },
    proccessEmailRestoring(data) {
    //   this.sendEmailRestoreUser({
    //     Nombre: data.Nombre,
    //     FarmaciaID: data.FarmaciaID,
    //     Correo: data.Correo,
    //   });
      alert(this.languaje == "es"?"Correo de restauracion ha sido enviado a "+data.Nombre:"Restauration Email has been sent to "+data.Nombre);
      this.$router.push("/");
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.log-in-bg {
  height: 100vh;
  /* background-image: url("../assets/background panel admin.jpg"); */
  background-size: cover;
  background-color: #e0e0e0;
}

.logo {
  width: 60%;
  margin: auto;
  display: block;
  margin-top: 50px;
}
.inputContainer {
  padding-top: 20px;
}
.card {
  padding-top: 50px;
  padding-bottom: 40px;
  border-radius: 25px;
  background-color: transparent;
  border-style: none;
}

.login-title {
  text-align: center;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 30px !important;
  font-size: 25px;
}

.center-div {
  padding-top: 50px;
}

.icon {
  color: #424242;
  font-size: 20px;
}

.email,
.password {
  margin-bottom: 30px;
  width: 100%;
  border: none;
  border-bottom: 2px solid #424242;
  font-family: "Montserrat", sans-serif;

  font-weight: 300;
  background-color: transparent;
}

.forgot-btn {
  text-decoration: none;
  color: rgb(0, 0, 0);
  font-family: "Montserrat", sans-serif;

  font-weight: 600;
}

.forgot-btn:hover {
  text-decoration: none;
  color: #424242;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

.button-position {
  display: flex;
  width: 420px;
  margin: auto;
  padding-top: 20px;
}

.submit-btn {
  background-color: #424242;
  border: none;
  padding: 8px;
  color: white;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 1px;
  width: 100%;
  height: 55px;
  margin: auto;
  display: block;
  -webkit-box-shadow: 0 8px 6px -6px black;
  -moz-box-shadow: 0 8px 6px -6px black;
  box-shadow: 0 8px 6px -6px black;
  /* margin: 5px; */
}
.required {
  font-size: 15px;
  padding: 5px;
  background-color: #ffcccc;
}

.submit-btn2 {
  background-color: #ffc93a;
  border: none;
  padding: 8px;
  color: white;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 1px;
  width: 200px;
  -webkit-box-shadow: 0 8px 6px -6px black;
  -moz-box-shadow: 0 8px 6px -6px black;
  box-shadow: 0 8px 6px -6px black;
  margin: 5px;
}
</style>
